<template>
  <div>
    <div class="flex justify-between">
      <div class="w-3/4">
        <p
          class="text-xl font-medium font-headers mb-3 font-bold text-grey-dark-2"
        >
          <span class="text-grey-medium-2 font-normal">
            Companies / ... /
          </span>
          Manage Communication
        </p>
      </div>
      <div class="flex space-x-2">
        <Button
          text="Back"
          type="primary-white"
          size="medium"
          :iconLeft="arrow"
          @click.native="goBack"
        />
        <Button
          text="Send Now"
          type="primary"
          size="medium"
          :disabled="!somethingChecked"
          @click.native="sendNow"
        />
      </div>
    </div>
    <div class="bg-white px-2 pt-3 pb-4 rounded shadow">
      <p class="mb-2 font-bold font-header text-grey-light text-mdl">
        {{ selectedAssessment.name }} - Email Notifications for Participants
      </p>
      <p class="text-grey-dark-1 text-mdh mb-6 w-1/2">
        Select which messages you want to resend to the selected participants
        from the previous step. Message settings and content can be changed by
        clicking “Edit” assessment. New messages can also be greated there.
      </p>
      <div v-for="(email, index) in emails" :key="index">
        <button class="flex items-center mb-3" @click="() => toggle(index)">
          <Checkbox :active="email.isSelected" />
          <p class="ml-2">{{ emailName(index) }}</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Button from '../../../components/Button/Button.vue';
import ParticipantsTable from '../components/ParticipantsTable';
import arrow from '@/assets/img/menu/arrow-left.svg';
export default {
  name: 'CompanyManageCommunicationStep2',
  components: { ParticipantsTable, Button },
  data: () => ({ arrow, emails: [] }),
  computed: {
    ...mapState({
      currentCompany: (state) => state.companies.currentCompany,
      selectedAssessment: (state) => state.companies.selectedAssessment,
      participants: (state) =>
        state.companies.participantsForSelectedAssessment,
      selectedParticipants: (state) =>
        state.companies.selectedParticipantsForSelectedAssessment,
      emailsForSelectedAssessment: (state) =>
        state.companies.emailsForSelectedAssessment,
    }),
    somethingChecked() {
      let ok = false;
      this.emails.map((item) => {
        if (item.isSelected) ok = true;
      });
      return ok;
    },
  },
  async mounted() {
    await this.getEmailsForActiveAssessment({
      surveyId: this.selectedAssessment.id,
      organizationId: this.currentCompany.id,
    });
    this.emails = this.emailsForSelectedAssessment.map((item) => ({
      ...item,
      isSelected: false,
    }));
  },
  methods: {
    ...mapActions({
      getEmailsForActiveAssessment: 'companies/getEmailsForActiveAssessment',
      sendEmail: 'companies/sendEmailToParticipants',
    }),
    emailName(index) {
      switch (this.emails[index].surveyMessageType) {
        case 1:
          return 'Invitation Email';
        case 3:
          return 'Reminder Email';
        default:
          return this.emails[index].messageName;
      }
    },
    toggle(index) {
      this.emails[index].isSelected = !this.emails[index].isSelected;
    },
    goBack() {
      this.$router.push(
        `/company/${this.currentCompany.id}/assessments/${this.selectedAssessment.id}/communication`
      );
    },
    sendNow() {
      this.emails.map(async (item) => {
        if (item.isSelected) await this.sendEmail({ surveyMessageId: item.id });
      });
      this.$router.push(`/company/${this.currentCompany.id}/assessments`);
    },
  },
};
</script>
